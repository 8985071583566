import React from 'react';

import styles from './OptionCard.module.scss';

function OptionCard(props) {
  return (
    <div className={styles['option-card']}>
      <h3>
        {props.title}
      </h3>
      <p>
        {props.desc}
      </p>
      <a href={props.link}>
        <button type="button">
          {props.buttonText}
        </button>
      </a>
    </div>
  );
}

export default OptionCard;
