import React from 'react';

import ModalButton from './ModalButton';
import ProgramSelect from './ProgramSelect';

import styles from './SchoolRow.module.scss';

function SchoolRow(props) {
  const moreInfoBtn = (
    <a href={props.school.website} target="_blank" rel="noopener noreferrer">
      <button className={styles.action} type="button">More Information</button>
    </a>
  );
  return (
    <div className={styles['school-container']}>
      <div className={styles.details}>
        <h2>{props.school.name}</h2>
        {props.school.region}
        <br />
        <br />
        {`CA$${props.school['Tution Fee - lowest']} - ${props.school['Tuition Fee - highest']}`}
      </div>
      <div className={styles.actions}>
        {props.school.website && props.school.website !== '' && moreInfoBtn}
        <ModalButton buttonClass={`${styles.action} ${styles.primary}`} buttonText="Select School">
          <ProgramSelect {...props} />
        </ModalButton>
      </div>
    </div>
  );
}

export default SchoolRow;
