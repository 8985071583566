import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import SplashView from './views/SplashView';
import GradeSchoolSearchView from './views/GradeSchoolSearchView';
import UniversitySearchView from './views/UniversitySearchView';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <SplashView />
        </Route>
        <Route exact path="/gradeschoolsearch">
          <GradeSchoolSearchView />
        </Route>
        <Route exact path="/universitysearch">
          <UniversitySearchView />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
