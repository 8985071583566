import React from 'react';

import Search from '../layouts/Search';

function UniversitySearchView() {
  const selections = {
    degree: true,
    program: true,
  };

  const refinements = [
    {
      value: 'region',
      label: 'Region',
    },
    {
      value: 'degrees',
      label: 'Degrees',
    },
    {
      value: 'programs',
      label: 'Programs',
    },
  ];

  return (
    <Search
      title="University / College Search"
      selections={selections}
      indexName="dev_Universities"
      refinements={refinements}
    />
  );
}

export default UniversitySearchView;
