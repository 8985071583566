import React, { useRef, useState } from 'react';

import styles from './ModalButton.module.scss';

function ModalButton(props) {
  const [visible, setVisible] = useState(false);
  const modalRef = useRef(null);
  const closeButtonRef = useRef(null);

  const close = (event) => {
    // If the close button or the background behind the modal is clicked
    if (closeButtonRef.current.contains(event.target) || !modalRef.current.contains(event.target)) {
      setVisible(false);
    }
  };

  const modal = (
    <div className={styles['modal-container']} onClick={close} role="button" tabIndex={0} onKeyPress={() => {}}>
      <div className={styles.modal} ref={modalRef}>
        <button className={styles['close-button']} ref={closeButtonRef} type="button">X</button>
        {props.children}
      </div>
    </div>
  );

  return (
    <>
      <button onClick={() => setVisible(true)} className={props.buttonClass} type="button">{props.buttonText}</button>
      {visible && modal}
    </>
  );
}

export default ModalButton;
