import React from 'react';

import styles from './Footer.module.scss';

function Footer() {
  return (
    <div className={styles['footer-container']}>
      <div className={styles.footer}>
        <div data-v-4116f01f="" className="row">
          <div data-v-4116f01f="" className="col-lg-4 info">
            {/* <div data-v-4116f01f="" className="social-icon">
              <div data-v-4116f01f="">
                <a data-v-4116f01f="" href="https://www.facebook.com/Supervisaai-101664981282858/" target="_blank" rel="noopener noreferrer">
                  <span data-v-4116f01f="" className="ti-facebook" />
                </a>
              </div>
              <div data-v-4116f01f="">
                <a data-v-4116f01f="" href="https://twitter.com/super_visa_ai" target="_blank" rel="noopener noreferrer">
                  <span data-v-4116f01f="" className="ti-twitter" />
                </a>
              </div>
              <div data-v-4116f01f="">
                <a data-v-4116f01f="" href="https://www.instagram.com/supervisa.ai/" target="_blank" rel="noopener noreferrer">
                  <span data-v-4116f01f="" className="ti-instagram" />
                </a>
              </div>
              <div data-v-4116f01f="">
                <a data-v-4116f01f="" href="https://www.linkedin.com/company/supervisa-ai/" target="_blank" rel="noopener noreferrer">
                  <span data-v-4116f01f="" className="ti-linkedin" />
                </a>
              </div>
            </div> */}

            <p data-v-4116f01f="" className="mb-0 f_400">
              <a data-v-4116f01f="" href="mailto:support@supervisas.com" className="contact-us" style={{ color: '#d1d1d1' }}>support@supervisas.com</a>
            </p>
            <p data-v-4116f01f="" className="mb-0 f_400 pt-2 pb-2">Copyright © 2020 SuperVisas, all rights reserved.</p>
          </div>
          <div data-v-4116f01f="" className="col-lg-8 terms">
            <p data-v-4116f01f="">
              SuperVisas is not a law firm and is not a substitute for the advice of a regulated immigration consultant.
              {' '}
              SuperVisas is not affiliated with or endorsed by Immigration,Refugee and Citizenship Canada (IRCC) or any other government agency.
              {' '}
              Use of the SuperVisas website and its services are subject to our
              {' '}
              <a data-v-4116f01f="" target="_blank" href="https://supervisa.ai/privacy-policy.pdf" rel="noopener noreferrer" style={{ color: '#d1d1d1' }}>Privacy Policy</a>
              {' '}
              and
              <a data-v-4116f01f="" target="_blank" href="https://supervisa.ai/terms-of-use.pdf" rel="noopener noreferrer" style={{ color: '#d1d1d1' }}>Terms of Use</a>
              . Regulated immigration consultant services are provided by independent regulated immigration consultant and are subject to a separate
              <a data-v-4116f01f="" target="_blank" href="https://supervisa.ai/representative-agreement.pdf" rel="noopener noreferrer" style={{ color: '#d1d1d1' }}>Representative Agreement</a>
              .
              {' '}
            </p>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Footer;
