import React from 'react';

import styles from './Page.module.scss';

function Page(props) {
  return (
    <div className={styles['page-container']}>
      <div className={styles['page-card']}>
        {props.children}
      </div>
    </div>
  );
}

export default Page;
