import React from 'react';

import styles from './ProgramSelect.module.scss';

function ProgramSelect(props) {
  const degreeOptions = props.school.degrees ? props.school.degrees.map((degree) => <option key={degree} value={degree}>{degree}</option>) : null;
  const degreeSelector = (
    <div className={styles['input-row']}>
      <label htmlFor="degree">Select the degree you would be applying for</label>
      <select name="degree" id="degree">
        {degreeOptions}
      </select>
    </div>
  );

  // const programInput = (
  //   <div className={styles['input-row']}>
  //     <label htmlFor="program">Type in your program</label>
  //     <input type="input" name="program" placeholder="Program Name" />
  //   </div>
  // );

  const gradeOptions = props.school.grades ? props.school.grades.map((grade) => <option key={grade} value={grade}>{grade}</option>) : null;
  const gradeSelector = (
    <div className={styles['input-row']}>
      <label htmlFor="grade">Select the grade year would be applying for</label>
      <select name="grade" id="grade">
        {gradeOptions}
      </select>
    </div>
  );

  return (
    <>
      <h1>
        {props.school.name}
      </h1>
      <h4>
        {props.school.region}
      </h4>

      <div>
        {props.degree && degreeSelector}
        {/* {props.program && programInput} */}
        {props.grade && gradeSelector}
        <a href="https://apply.supervisas.com/user/newapplication?showType=study">
          <button className={styles.submit} type="link">Check Eligibility on SuperVisas.com</button>
        </a>
        <button className={styles.action} type="button" onClick={() => navigator.clipboard.writeText(props.school.name)}>Copy School To Clipboard</button>
        <div className="clear-both" />
      </div>
    </>
  );
}

export default ProgramSelect;
