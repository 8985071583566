import React from 'react';

import Footer from '../components/Footer';
import Page from '../layouts/Page';
import OptionCard from '../components/OptionCard';

import logo from '../assets/bluelogo.svg';
import styles from './SplashView.module.scss';

function SplashView() {
  return (
    <div>
      <Page>
        <div className={styles.logo}>
          <img alt="SuperVisas Logo" src={logo} width={220} />
        </div>
        <div className={styles['splash-container']}>
          <h1>
            See if you're eligible for a free school and visa application
          </h1>
          <div className="spacer" />
          <div>
            <OptionCard
              title="High Schools / Secondary Schools"
              desc="By partnering with exceptional schools in Canada we are able to provide visas and applications on their behalf for no extra cost."
              buttonText="Search through our partner schools"
              link="/gradeschoolsearch"
            />
            <OptionCard
              title="Universities / Colleges"
              desc="By partnering with world-class Universites and Colleges in Canada
              we are able to provide visas and applications to the institutions on their behalf."
              buttonText="Search through our partner Post-secondary institutions"
              link="/universitysearch"
            />
          </div>

        </div>


      </Page>
      <Footer />
    </div>
  );
}

export default SplashView;
