import React from 'react';
import {
  InstantSearch,
  Hits,
  Pagination,
  ClearRefinements,
  RefinementList,
  Configure,
} from 'react-instantsearch-dom';

import Footer from '../components/Footer';
import SchoolRow from '../components/SchoolRow';
import SearchBar from '../components/SearchBar';
import Page from './Page';
import searchClient from '../utils/SearchClient';

import logo from '../assets/bluelogo.svg';
import styles from './Search.module.scss';

function Search(props) {
  const refinements = props.refinements ? props.refinements.map((refinement) => (
    <div key={refinement.value}>
      <h4>{refinement.label}</h4>
      <RefinementList attribute={refinement.value} />
      <br />
    </div>
  )) : null;

  return (
    <div>
      <Page>
        <a className={styles.logo} href="https://supervisas.com/">
          <img alt="SUPERVISA Logo" src={logo} width={220} />
        </a>
        <h1>{props.title}</h1>
        <p>
          All listed schools have free applications through:
          <a href="https://apply.supervisas.com/user/newapplication?showType=study"> https://apply.supervisas.com/user/newapplication?showType=study</a>
        </p>
        <InstantSearch indexName={props.indexName} searchClient={searchClient}>
          <div className={styles['two-col-layout']}>
            <div className={styles['left-panel']}>
              {refinements}
              <Configure hitsPerPage={8} />
              <ClearRefinements />
            </div>
            <div className={styles['mobile-clear']} />
            <div className={styles['right-panel']}>
              <SearchBar />
              <Hits hitComponent={(res) => <SchoolRow school={res.hit} {...props.selections} />} />
              <div className={styles['always-clear']} />
              <Pagination />
            </div>
          </div>
        </InstantSearch>
        <div className={styles['always-clear']} />
      </Page>
      <Footer />
    </div>
  );
}

export default Search;
