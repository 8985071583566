import React from 'react';

import Search from '../layouts/Search';

function GradeSchoolSearchView() {
  const selections = {
    grade: true,
  };

  const refinements = [
    {
      value: 'region',
      label: 'Region',
    },
    {
      value: 'grades',
      label: 'Grade',
    }
  ];

  return (
    <Search
      title="Grade School Search"
      selections={selections}
      indexName="dev_GradeSchools"
      refinements={refinements}
    />
  );
}

export default GradeSchoolSearchView;
